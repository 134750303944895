import { render, staticRenderFns } from "./extra-allowance-list.vue?vue&type=template&id=50005b9f&scoped=true"
import script from "./extra-allowance-list.vue?vue&type=script&lang=js"
export * from "./extra-allowance-list.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50005b9f",
  null
  
)

export default component.exports